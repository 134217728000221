<template>
  <div>
    <div
      class="message-header"
      :style="{
        color: $store.getters.color.fontColor,
        backgroundColor: $store.getters.color.color1,
      }"
    >
      <label>
        <select
          class="channel-select"
          v-model="currentChannel"
          :style="{
            color: $store.getters.color.fontColor,
            backgroundColor: $store.getters.color.color1,
          }"
        >
          <option
            v-for="(matrixChannel, matrixChannelId) in channelList"
            :value="matrixChannel"
            :key="matrixChannel.id"
            :selected="firstSelect[matrixChannelId]"
          >
            {{ matrixChannel.name }}
          </option>
        </select>
      </label>
    </div>
    <div class="client-container">
      <div
        class="message-container"
        id="msg-id-cont"
        @scroll="scrollHandle"
        ref="messageContainer"
      >
        <div v-for="(message, key) in sortedMessages" :key="key">
          <p :class="computeClass(message.sender)">{{ message.senderName }}</p>
          <p
            :class="message.sender"
            :style="{
              color: $store.getters.color.fontColor,
              backgroundColor:
                message.sender == 'me-msg'
                  ? $store.getters.color.color1
                  : message.sender == 'you-msg'
                  ? $store.getters.color.color3
                  : $store.getters.color.color2,
            }"
            v-html="parseMessage(message.content, message.date, message.sender)"
          ></p>
        </div>
      </div>
      <div class="bottom-bar">
        <label
          for="fileUpload"
          class="upload-documents"
          :style="{
            color: $store.getters.color.fontColor,
            backgroundColor: $store.getters.color.color2,
          }"
        >
          <br />
          <br />+
        </label>
        <input
          type="file"
          name="fileUpload"
          id="fileUpload"
          @change="parseUploadedFile"
          :disabled="
            currentChannel == '' ||
            currentChannel == null ||
            (currentChannel.hasOwnProperty('startDate') &&
              currentChannel.hasOwnProperty('endDate') &&
              (new Date(currentChannel.startDate) >
                new Date(new Date().setDate(new Date().getDate() + 2)) ||
                new Date(currentChannel.endDate) <
                  new Date(new Date().setDate(new Date().getDate() - 2))))
          "
        />
        <textarea
          class="msg-input"
          v-model="currentlyWriting"
          @keypress.enter.exact.prevent="sendMessage"
          @keypress.shift.enter.exact.prevent="currentlyWriting += '\n'"
          ref="messageInput"
          :disabled="
            currentChannel == '' ||
            currentChannel == null ||
            (currentChannel.hasOwnProperty('startDate') &&
              currentChannel.hasOwnProperty('endDate') &&
              (new Date(currentChannel.startDate) >
                new Date(new Date().setDate(new Date().getDate() + 2)) ||
                new Date(currentChannel.endDate) <
                  new Date(new Date().setDate(new Date().getDate() - 2))))
          "
          @input="
            $refs.messageInput.style.height = '';
            $refs.messageInput.style.height =
              Math.min($refs.messageInput.scrollHeight, 150) + 'px';
            $refs.messageContainer.style.height =
              '100hv - ' + $refs.messageInput.style.height + 'px';
          "
        ></textarea>
        <div
          class="emoji-div"
          v-click-outside="hideEmojis"
          :style="{ right: getPos() }"
        >
          <div class="emoji-container" v-show="showEmoji">
            <span
              v-for="n in 79"
              @click="
                currentlyWriting =
                  currentlyWriting.slice(0, $refs.messageInput.selectionStart) +
                  getEmoticoneCode(n) +
                  currentlyWriting.slice($refs.messageInput.selectionStart)
              "
              :key="n"
              class="an-emoji"
              v-html="getEmoticone(n)"
            ></span>
          </div>
          <button
            class="emoji-button"
            @click="showEmoji = !showEmoji"
            v-html="this.$twemoji.parse('\ud83d\ude01')"
          ></button>
        </div>
        <button
          class="send-button"
          @click="sendMessage"
          :style="{
            color: $store.getters.color.fontColor,
            backgroundColor: $store.getters.color.color1,
          }"
        >
          Send
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getRoomRepository } from "../roomRepository";
//import MatrixHandler from "../matrixHandler";
import HandleApi from "../apiHandle";
import sanitizeHtml from "sanitize-html";

export default {
  name: "Message",
  data() {
    return {
      messages: [],
      showEmoji: false,
      currentlyWriting: "",
      client: {},
      loadingMore: true,
      messageLoad: 50,
      matrixConf: {},
      currentChannel: {},
      channelList: [],
      clientNameMap: {},
      firstSelect: [],
      roomRepository: getRoomRepository(),
      room: null,
    };
  },
  computed: {
    newMessages: function () {
      if (this.messages.length == this.messageLoad - 20) {
        return false;
      }
      return true;
    },
    sortedMessages: function () {
      let res = [];
      for (let i = 0; i < this.messages.length; i++) {
        if (this.messages[i].content.msgtype) {
          if (this.messages[i].room_id == this.currentChannel.matrixRoomId) {
            if (this.messages[i].content.msgtype == "m.notice") {
              res.push({
                sender: "event",
                date: new Date(this.messages[i].origin_server_ts),
                content: this.messages[i].content.formatted_body,
                senderName: "",
              });
            } else {
              let msgSender = this.messages[i].sender
                ? this.messages[i].sender
                : this.messages[i].user_id;
              if (msgSender == this.matrixConf.userId) {
                if (
                  this.messages[i].content.msgtype == "m.file" ||
                  this.messages[i].content.msgtype == "m.image" ||
                  this.messages[i].content.msgtype == "m.audio" ||
                  this.messages[i].content.msgtype == "m.video"
                ) {
                  res.push({
                    sender: "me-msg",
                    date: new Date(this.messages[i].origin_server_ts),
                    content: this.messages[i].content,
                    senderName: "",
                  });
                } else {
                  res.push({
                    sender: "me-msg",
                    date: new Date(this.messages[i].origin_server_ts),
                    content: this.messages[i].content.body,
                    senderName: "",
                  });
                }
              } else {
                if (
                  this.currentChannel.hasOwnProperty("tourAgency") &&
                  msgSender == this.currentChannel.tourAgency.matrixId.id
                ) {
                  if (
                    this.messages[i].content.msgtype == "m.file" ||
                    this.messages[i].content.msgtype == "m.image" ||
                    this.messages[i].content.msgtype == "m.audio" ||
                    this.messages[i].content.msgtype == "m.video"
                  ) {
                    res.push({
                      sender: "you-msg",
                      date: new Date(this.messages[i].origin_server_ts),
                      content: this.messages[i].content,
                      senderName: this.currentChannel.tourAgency.name,
                    });
                  } else {
                    res.push({
                      sender: "you-msg",
                      date: new Date(this.messages[i].origin_server_ts),
                      content: this.messages[i].content.body,
                      senderName: this.currentChannel.tourAgency.name,
                    });
                  }
                } else {
                  if (
                    this.messages[i].content.msgtype == "m.file" ||
                    this.messages[i].content.msgtype == "m.image" ||
                    this.messages[i].content.msgtype == "m.audio" ||
                    this.messages[i].content.msgtype == "m.video"
                  ) {
                    res.push({
                      sender: "he-msg",
                      date: new Date(this.messages[i].origin_server_ts),
                      content: this.messages[i].content,
                      senderName: this.clientNameMap[msgSender]
                        ? this.clientNameMap[msgSender]
                        : "",
                    });
                  } else {
                    res.push({
                      sender: "he-msg",
                      date: new Date(this.messages[i].origin_server_ts),
                      content: this.messages[i].content.body,
                      senderName: this.clientNameMap[msgSender]
                        ? this.clientNameMap[msgSender]
                        : "",
                    });
                  }
                }
              }
            }
          }
        }
      }
      res.sort(function (a, b) {
        let date1 = a.date;
        let date2 = b.date;
        if (date1 > date2) {
          return 1;
        } else {
          if (date1 == date2) {
            return 0;
          } else {
            return -1;
          }
        }
      });

      return res;
    },
  },
  methods: {
    computeClass(msgSender) {
      if (msgSender == "me-msg") {
        return "name-right";
      } else {
        if (msgSender == "event") {
          return "name-no-display";
        }
        return "name-left";
      }
    },
    getPos() {
      var element = document.getElementsByClassName("emoji-button");
      if (element.length > 0) {
        return element[0].getBoundingClientRect().right;
      }
      return -77;
    },
    getEmoticone(n) {
      if (n < 16) {
        return this.$twemoji.parse(
          String.fromCodePoint("0x1f60" + n.toString(16))
        );
      }
      return this.$twemoji.parse(
        String.fromCodePoint("0x1f6" + n.toString(16))
      );
    },
    getEmoticoneCode(n) {
      if (n < 16) {
        return String.fromCodePoint("0x1f60" + n.toString(16));
      }

      return String.fromCodePoint("0x1f6" + n.toString(16));
    },
    hideEmojis() {
      this.showEmoji = false;
    },
    parseUploadedFile(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];
        this.client.upload(file, this.currentChannel.matrixRoomId);
      }
    },
    parseMessage(text, date, messageSender) {
      if (
        messageSender != "event" &&
        !(
          text.hasOwnProperty("msgtype") &&
          (text.msgtype == "m.file" ||
            text.msgtype == "m.image" ||
            text.msgtype == "m.audio" ||
            text.msgtype == "m.video")
        )
      ) {
        text = sanitizeHtml(text, { disallowedTagsMode: "escape" });
        text = text.replace(/(?:\r\n|\r|\n)/g, "<br>");
        var exp =
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
        let todayDate = new Date();
        let printDate = this.beautifulDate(date, false);
        if (
          todayDate.getFullYear() != date.getFullYear() ||
          todayDate.getMonth() != date.getMonth() ||
          todayDate.getDay() != date.getDay()
        ) {
          printDate = this.beautifulDate(date, true);
        }
        return this.$twemoji.parse(
          text.replace(exp, "<a href='$1' target='_blank'>$1</a>") +
            "</br>" +
            "<p class='message-date'>" +
            printDate +
            "</p>"
        );
      } else {
        if (messageSender == "event") {
          return text;
        } else {
          let docUrl = text.url.substring(6);
          if (text.msgtype == "m.image") {
            return (
              '<a target="_blank" rel="noopener" href="' +
              this.matrixConf.baseUrl +
              "/_matrix/media/r0/download/" +
              docUrl +
              '"><img src="' +
              this.matrixConf.baseUrl +
              "/_matrix/media/r0/thumbnail/" +
              docUrl +
              '?width=100&amp;height=100" alt="' +
              sanitizeHtml(text.body) +
              '">' +
              "<br/>↓ " +
              sanitizeHtml(text.body) +
              "</a>"
            );
          } else {
            if (text.msgtype == "m.audio") {
              return (
                '<audio controls> <source src="' +
                this.matrixConf.baseUrl +
                "/_matrix/media/r0/download/" +
                docUrl +
                '" type="' +
                text.info.mimetype +
                '"> Your browser does not support the audio element. </audio>' +
                '<a target="_blank" rel="noopener" href="' +
                this.matrixConf.baseUrl +
                "_matrix/media/r0/download/" +
                docUrl +
                '">' +
                "<br/>↓ " +
                sanitizeHtml(text.body) +
                "</a>"
              );
            } else {
              if (text.msgtype == "m.video") {
                return (
                  '<video controls height="300"> <source src="' +
                  this.matrixConf.baseUrl +
                  "/_matrix/media/r0/download/" +
                  docUrl +
                  '" type="' +
                  text.info.mimetype +
                  '"> Your browser does not support the video element. </video>' +
                  '<a target="_blank" rel="noopener" href="' +
                  this.matrixConf.baseUrl +
                  "_matrix/media/r0/download/" +
                  docUrl +
                  '">' +
                  "<br/>↓ " +
                  sanitizeHtml(text.body) +
                  "</a>"
                );
              } else {
                return (
                  '<a target="_blank" rel="noopener" href="' +
                  this.matrixConf.baseUrl +
                  "/_matrix/media/r0/download/" +
                  docUrl +
                  '">↓ ' +
                  text.body +
                  "</a>"
                );
              }
            }
          }
        }
      }
    },
    beautifulDate(date, full) {
      let result = "";
      if (full) {
        if (date.getDate() < 10) {
          result += "0" + date.getDate();
        } else {
          result += date.getDate();
        }
        result += " ";
        switch (date.getMonth()) {
          case 0:
            result += "Jan";
            break;
          case 1:
            result += "Feb";
            break;
          case 2:
            result += "Mar";
            break;
          case 3:
            result += "Apr";
            break;
          case 4:
            result += "May";
            break;
          case 5:
            result += "Jun";
            break;
          case 6:
            result += "Jul";
            break;
          case 7:
            result += "Aug";
            break;
          case 8:
            result += "Sep";
            break;
          case 9:
            result += "Oct";
            break;
          case 10:
            result += "Nov";
            break;
          case 11:
            result += "Dec";
        }
        result += ", ";
      }
      if (date.getHours() < 10) {
        result += "0" + date.getHours();
      } else {
        result += date.getHours();
      }
      result += ":";
      if (date.getMinutes() < 10) {
        result += "0" + date.getMinutes();
      } else {
        result += date.getMinutes();
      }
      return result;
    },
    async sendMessage() {
      if (this.currentlyWriting.trim() != "") {
        /*this.client.sendMessage(
          this.currentChannel.matrixRoomId,
          this.currentlyWriting.trim()
        );*/
        await this.room.sendMessage({
          type: "m.message",
          body: this.currentlyWriting.trim(),
        });
        this.currentlyWriting = "";
        this.$nextTick(() => {
          this.$refs.messageInput.style.height = "";
          this.$refs.messageInput.style.height =
            Math.min(this.$refs.messageInput.scrollHeight, 100) + "px";
          this.$refs.messageContainer.style.height =
            "100hv - " + this.$refs.messageInput.style.height + "px";
        });
      }
    },
    async scrollBack(load) {
      let oldHeight = this.$refs.messageContainer.scrollHeight;
      this.loadingMore = true;
      /*let newMessages = await this.client.scrollback(
        this.currentChannel.matrixRoomId,
        load
      );*/
      await this.room.scrollBack();
      /*let newMsgCount = 0;
      newMessages.messages.chunk.forEach((message) => {
        if (message.type == "m.room.message") {
          newMsgCount += 1;
        }
      });
      if (this.sortedMessages.length < newMsgCount) {
        this.messages = this.client.watch();
        this.messages.forEach((message) => {
          if (message.room_id != this.matrixRoomId) {
            this.messages.push(message);
          }
        });
        newMessages.messages.chunk.forEach((message) => {
          if (message.type == "m.room.message") {
            this.messages.push(message);
          }
        });*/

        this.$nextTick(() => {
          this.$refs.messageContainer.scrollTop =
            this.$refs.messageContainer.scrollHeight - oldHeight;
        });
      //}
    },
    scrollHandle() {
      if (this.$refs.messageContainer.scrollTop == 0) {
        this.scrollBack(this.messages.length + 20);
        this.messageLoad = this.messages.length + 20;
      }
    },
    isClient(username) {
      if (this.clientNameMap.hasOwnProperty(username)) {
        return false;
      }
      return true;
    },
  },
  async created() {
    try {
      this.$store.dispatch("load", "Loading your tchat");
      this.matrixConf = await HandleApi.getMessageAuth(
        this.$store.getters.userId
      );
      /*this.client = new MatrixHandler(
        this.matrixConf.baseUrl,
        this.matrixConf.accessToken,
        this.matrixConf.userId
      );*/
      this.room = this.roomRepository.getRoom(
        "travel",
        this.matrixConf.travelInformation._id
      );
      for (let i = 0; i < this.matrixConf.travelInformation.length; i++) {
        for (
          let j = 0;
          j < this.matrixConf.travelInformation[i].client.length;
          j++
        ) {
          if (
            this.matrixConf.travelInformation[i].client[j].messageApp.app ==
              "matrix" &&
            this.matrixConf.travelInformation[i].client[j].client_id
          ) {
            this.channelList.push(this.matrixConf.travelInformation[i]);
            this.clientNameMap[
              this.matrixConf.travelInformation[i].client[j].messageApp
                .identifier
            ] ==
              this.matrixConf.travelInformation[i].client[j].client_id.name +
                " " +
                this.matrixConf.travelInformation[i].client[j].client_id
                  .surname;
          }
        }
        if (
          new Date(this.matrixConf.travelInformation[i].startDate) < new Date()
        ) {
          this.firstSelect.push(true);
          for (let j = 0; j < i; j++) {
            this.firstSelect[j] = false;
          }
          this.currentChannel = this.matrixConf.travelInformation[i];
        } else {
          this.firstSelect.push(false);
        }
      }
      //this.client.start(this.messageLoad);
      //this.messages = this.client.watch();
      this.messages = this.room.messages; // reference, not value
      this.$store.dispatch("unload");
    } catch (err) {
      this.$store.dispatch("unload");
      window.console.log(err);
    }
  },
  watch: {
    sortedMessages: function () {
      this.$nextTick(function () {
        if (!this.loadingMore) {
          this.$refs.messageContainer.scrollTop =
            this.$refs.messageContainer.scrollHeight;
        }
        if (this.sortedMessages.length != this.oldLength) {
          this.oldLength = this.sortedMessages.length;
          this.$emit("newMessages");
        }
        if (this.loadingMore) {
          this.loadingMore = false;
        }
      });
    },
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== "function") {
          const compName = vNode.context.name;
          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
          if (compName) {
            warn += `Found in component '${compName}'`;
          }

          window.console.warn(warn);
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;

        // add Event Listeners
        document.addEventListener("click", handler);
      },

      unbind: function (el) {
        // Remove Event Listeners
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },
};
</script>

<style scoped>
.message-header {
  max-width: 980px;
  padding: 20px;
  font-size: 25px;
  color: white;
  font-weight: bold;
  background-color: #00909f;
  border-radius: 20px 20px 0px 0px;
  border: 2px solid lightgrey;
  text-align: left;
  margin: 0 auto;
}

.message-header select {
  background-color: #00909f;
  color: white;
  border: none;
  font-weight: bold;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2px solid white;
  padding: 5px;
  width: 500px;
  max-width: 100%;
}

.message-header label {
  position: relative;
}
.message-header label:after {
  content: "v";
  color: #fff;
  right: 8px;
  top: 2px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
}
.message-header label:before {
  content: "";
  right: 6px;
  top: 0px;
  width: 20px;
  height: 20px;
  position: absolute;
  pointer-events: none;
  display: block;
}

.message-header select::-ms-expand {
  display: none;
}

.client-container {
  align-items: center;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  max-width: 1000px;
  border-left: 2px solid lightgrey;
  border-right: 2px solid lightgrey;
  border-bottom: 2px solid lightgrey;
}

.load-more-button-container {
  text-align: center;
}

.load-more-button {
  background-color: rgb(153, 210, 208);
  color: white;
  font-weight: bold;
  border: none;
  padding: 10px;
  border-radius: 20px;
  font-size: 15px;
}

.message-container {
  height: calc(100vh - 150px);
  overflow-y: scroll;
}

.message-container >>> a {
  color: white;
  text-decoration: none;
}

.message-container >>> a:hover {
  text-decoration: underline;
}

.message-container >>> a:visited {
  color: white;
  text-decoration: none;
}

.event {
  color: lightgrey;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  margin: 0 auto;
  width: 80%;
}

.event >>> p {
  margin: 0;
  color: white;
  font-weight: bold;
  background-color: rgb(153, 210, 208);
  display: inline-block;
  padding: 10px;
  border-radius: 20px;
}

.event >>> hr {
  margin-bottom: 0;
}

.name-right {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: 15px;
  width: max-content;
  color: rgb(130, 130, 130);
}

.name-left {
  margin-top: 0px;
  margin-bottom: 0px;
  color: rgb(130, 130, 130);
  margin-right: auto;
  margin-left: 0px;
}

.name-no-display {
  display: none;
}

.me-msg {
  background-color: #00909f;
  color: white;
  width: max-content;
  max-width: 80%;
  padding: 7px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: 10px;
  margin-top: 1px;
  word-wrap: break-word;
}

.you-msg {
  background-color: #034c57;
  color: white;
  width: max-content;
  max-width: 80%;
  padding: 7px;
  border-radius: 10px;
  margin-right: auto;
  margin-left: 0px;
  margin-top: 1px;
  word-wrap: break-word;
}

.he-msg {
  background-color: #009fbc;
  color: white;
  width: max-content;
  max-width: 80%;
  padding: 7px;
  border-radius: 10px;
  margin-right: auto;
  margin-left: 0px;
  margin-top: 1px;
  word-wrap: break-word;
}

.bottom-bar {
  display: grid;
  grid-template-columns: 1fr 6fr 1fr 1fr;
  column-gap: 10px;
  padding-top: 5px;
  border-top: 1px solid lightgrey;
  min-height: 40px;
}

.bottom-bar input {
  width: 95%;
  border-radius: 20px;
  padding-left: 10px;
  border: 1px solid lightgrey;
  font-size: 20px;
  z-index: 5;
  padding-right: 10px;
  overflow-wrap: break-word;
  grid-column-start: 2;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
}

.msg-input {
  width: 95%;
  border-radius: 20px;
  padding-left: 15px;
  padding-top: 2px;
  border: 1px solid lightgrey;
  font-size: 16px;
  z-index: 5;
  padding-right: 15px;
  overflow-wrap: break-word;
  grid-column-start: 2;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
  resize: none;
}

input[type="file"] {
  display: none;
}

.upload-documents {
  background-color: rgb(153, 210, 208);
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  vertical-align: middle;
  line-height: 8px;
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
  height: 40px;
  margin-top: auto;
  margin-bottom: auto;
}

.upload-documents:hover {
  text-decoration: underline;
}

.emoji-button {
  width: 100%;
  height: 100%;
  background-color: white;
  border: 2px solid white;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  grid-column-start: 3;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
}

.emoji-button:hover {
  border-color: lightgrey;
}

.send-button {
  background-color: rgb(153, 210, 208);
  color: white;
  font-size: 15px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  grid-column-start: 4;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
}

.send-button:hover {
  text-decoration: underline;
}

.emoji-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 250px;
  height: 200px;
  overflow-y: scroll;
  border: 1px solid grey;
  justify-items: center;
  align-items: center;
  bottom: -15px;
  background-color: white;
  position: absolute;
  padding: 5px;
  right: calc(50% - 388px);
}

@media only screen and (max-width: 1000px) {
  .emoji-container {
    right: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .bottom-bar {
    column-gap: 5px;
  }

  .emoji-div {
    display: none;
  }

  .emoji-button {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.an-emoji {
  border: 1px solid white;
  cursor: pointer;
  padding: 3px;
  border-radius: 5px;
}

.an-emoji:hover {
  border-color: lightgray;
}

.channel-select {
  z-index: 1;
}
</style>

<style>
.message-date {
  text-align: right;
  font-size: 12px;
  color: rgb(196, 196, 196);
  margin-top: 5px;
  margin-bottom: 0;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}
video {
  max-width: 70vw;
}

img {
  max-width: 70vw;
}
</style>
