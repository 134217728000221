var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"message-header",style:({
      color: _vm.$store.getters.color.fontColor,
      backgroundColor: _vm.$store.getters.color.color1,
    })},[_c('label',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentChannel),expression:"currentChannel"}],staticClass:"channel-select",style:({
          color: _vm.$store.getters.color.fontColor,
          backgroundColor: _vm.$store.getters.color.color1,
        }),on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.currentChannel=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.channelList),function(matrixChannel,matrixChannelId){return _c('option',{key:matrixChannel.id,domProps:{"value":matrixChannel,"selected":_vm.firstSelect[matrixChannelId]}},[_vm._v(" "+_vm._s(matrixChannel.name)+" ")])}),0)])]),_c('div',{staticClass:"client-container"},[_c('div',{ref:"messageContainer",staticClass:"message-container",attrs:{"id":"msg-id-cont"},on:{"scroll":_vm.scrollHandle}},_vm._l((_vm.sortedMessages),function(message,key){return _c('div',{key:key},[_c('p',{class:_vm.computeClass(message.sender)},[_vm._v(_vm._s(message.senderName))]),_c('p',{class:message.sender,style:({
            color: _vm.$store.getters.color.fontColor,
            backgroundColor:
              message.sender == 'me-msg'
                ? _vm.$store.getters.color.color1
                : message.sender == 'you-msg'
                ? _vm.$store.getters.color.color3
                : _vm.$store.getters.color.color2,
          }),domProps:{"innerHTML":_vm._s(_vm.parseMessage(message.content, message.date, message.sender))}})])}),0),_c('div',{staticClass:"bottom-bar"},[_c('label',{staticClass:"upload-documents",style:({
          color: _vm.$store.getters.color.fontColor,
          backgroundColor: _vm.$store.getters.color.color2,
        }),attrs:{"for":"fileUpload"}},[_c('br'),_c('br'),_vm._v("+ ")]),_c('input',{attrs:{"type":"file","name":"fileUpload","id":"fileUpload","disabled":_vm.currentChannel == '' ||
          _vm.currentChannel == null ||
          (_vm.currentChannel.hasOwnProperty('startDate') &&
            _vm.currentChannel.hasOwnProperty('endDate') &&
            (new Date(_vm.currentChannel.startDate) >
              new Date(new Date().setDate(new Date().getDate() + 2)) ||
              new Date(_vm.currentChannel.endDate) <
                new Date(new Date().setDate(new Date().getDate() - 2))))},on:{"change":_vm.parseUploadedFile}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentlyWriting),expression:"currentlyWriting"}],ref:"messageInput",staticClass:"msg-input",attrs:{"disabled":_vm.currentChannel == '' ||
          _vm.currentChannel == null ||
          (_vm.currentChannel.hasOwnProperty('startDate') &&
            _vm.currentChannel.hasOwnProperty('endDate') &&
            (new Date(_vm.currentChannel.startDate) >
              new Date(new Date().setDate(new Date().getDate() + 2)) ||
              new Date(_vm.currentChannel.endDate) <
                new Date(new Date().setDate(new Date().getDate() - 2))))},domProps:{"value":(_vm.currentlyWriting)},on:{"keypress":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();return _vm.sendMessage.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if(!$event.shiftKey){ return null; }if($event.ctrlKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();_vm.currentlyWriting += '\n'}],"input":[function($event){if($event.target.composing){ return; }_vm.currentlyWriting=$event.target.value},function($event){_vm.$refs.messageInput.style.height = '';
          _vm.$refs.messageInput.style.height =
            Math.min(_vm.$refs.messageInput.scrollHeight, 150) + 'px';
          _vm.$refs.messageContainer.style.height =
            '100hv - ' + _vm.$refs.messageInput.style.height + 'px';}]}}),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideEmojis),expression:"hideEmojis"}],staticClass:"emoji-div",style:({ right: _vm.getPos() })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEmoji),expression:"showEmoji"}],staticClass:"emoji-container"},_vm._l((79),function(n){return _c('span',{key:n,staticClass:"an-emoji",domProps:{"innerHTML":_vm._s(_vm.getEmoticone(n))},on:{"click":function($event){_vm.currentlyWriting =
                _vm.currentlyWriting.slice(0, _vm.$refs.messageInput.selectionStart) +
                _vm.getEmoticoneCode(n) +
                _vm.currentlyWriting.slice(_vm.$refs.messageInput.selectionStart)}}})}),0),_c('button',{staticClass:"emoji-button",domProps:{"innerHTML":_vm._s(this.$twemoji.parse('\ud83d\ude01'))},on:{"click":function($event){_vm.showEmoji = !_vm.showEmoji}}})]),_c('button',{staticClass:"send-button",style:({
          color: _vm.$store.getters.color.fontColor,
          backgroundColor: _vm.$store.getters.color.color1,
        }),on:{"click":_vm.sendMessage}},[_vm._v(" Send ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }